<template>
  <div class="w-100vw sm:w-full h-8vh px-5vw sm:h-48px flex sm:pr-20px sm:pl-24px justify-between items-center bg-white topBannerContainer text-750rem sm:text-14px">
    <div class=" flex items-center">
      <img
      @click="handleMenuClick"
      class=" inline-block sm:hidden w-25px h-16px mr-2vw"
      src="@/assets/icon/menu_blue.png" alt="">
      <span class=" text-blue-main">{{ user.mist.firstname }}, Welcome <p class=" hidden sm:inline">to IE Money</p></span>
      <!-- <span class="black45 marginLeft20 sm:block hidden"
        >上次登录：22 八月 2020 2:05:15 AM GMT</span
      > -->
    </div>
    <div class="flex items-center">
      <img class=" px-2vw py-2vw  cursor-pointer  sm:px-24px sm:py-10px " src="@/assets/icon/email.svg" alt="" />
      <span class="divider text-black-65">｜</span>
      <span class=" px-2vw py-2vw sm:px-24px sm:py-10px  cursor-pointer  text-black-65">Need help?</span>
      <span class=" text-black-65">｜</span>
      <span  class=" cursor-pointer px-2vw py-2vw  sm:px-24px sm:py-10px text-black-65"
        @click="handleQuit">Quit</span
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { removeLocalStorage } from '@/utils/localStorage'
export default {
  created () {
  },
  props: {
    username: {
      type: String,
      default: 'Andy'
    }
  },
  computed: {
    ...mapState('login', ['user'])
  },
  methods: {
    handleMenuClick () {
      this.$emit('iconClick')
    },
    handleQuit () {
      this.$router.push('/')
      removeLocalStorage('token')
      removeLocalStorage('user')
    }
  }
}

</script>

<style scoped>
.topBannerContainer {
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}

</style>
